import { inject, Injectable, signal } from '@angular/core';
import { map } from 'rxjs';
import { ClientHttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private baseUrl = '/player-svc/v1/';
  httpService = inject(ClientHttpService);

  addBankAccountSuccess = signal<boolean>(false);
  currentPendingTransaction = signal<object>({});
  showTransactionStatus = signal<boolean>(false);

  getBankList() {
    return this.httpService.get(this.baseUrl + 'bank/list').pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  addBankAccount(payload: {
    paymentMethodRecId: string;
    bankNo: string;
    name: string;
  }) {
    return this.httpService.post(`${this.baseUrl}bank/add`, payload);
  }

  getCurrentPendingTransaction(recId: string) {
    return this.httpService
      .get(`${this.baseUrl}pending/${recId}`)
      .pipe(map((res: any) => res.data));
  }
}
